import axios from 'axios'
import { cleanBlankValue } from 'utils/helper'

export const addFinanceProduct = (data) => {
  return axios.post('/finance/products', data)
}

export const addFinanceProductPackage = (data) => {
  return axios.post('/finance/products/bundlePackages', data)
}

export const deleteFinanceProductPhoto = (url) => {
  return axios.delete('/finance/products/photo', { data: { url } })
}

export const deleteFinanceProductPhotoOnEdit = ({ url, id }) => {
  return axios.delete(`/finance/products/${id}/photo`, { data: { url } })
}

export const conversionProduct = (data) => {
  return axios.post('/finance/products/conversions', data)
}

export const editConversionProduct = (id, data) => {
  return axios.put(`/finance/products/conversions/${id}`, data)
}

export const deleteConversionProduct = (id) => {
  return axios.delete(`/finance/products/conversions/${id}`)
}

export const editFinanceProductPackage = (data) => {
  return axios.put(`/finance/products/bundlePackages/${data.id}`, data)
}

export const addFinanceProductManufacture = (data) => {
  return axios.post('/finance/products/bundleManufactures', data)
}

export const editFinanceProductManufacture = (data) => {
  return axios.put(`/finance/products/bundleManufactures/${data.id}`, data)
}

export const editFinanceProduct = (data) => {
  return axios.put(`/finance/products/${data.id}`, data)
}

export const addFinanceCategorie = (data) => {
  return axios.post('/finance/productCategories', data)
}

export const uploadImportFinanceProduct = (data) => {
  return axios.postForm('/finance/products/uploadImport', data)
}

export const executeImportFinanceProduct = (data) => {
  return axios.post('/finance/products/executeImport', data)
}

export const uploadImportFinanceProductManufacture = (data) => {
  return axios.postForm('/finance/products/bundleManufactures/uploadImport', data)
}

export const executeImportFinanceProductManufacture = (data) => {
  return axios.post('/finance/products/bundleManufactures/executeImport', data)
}

export const uploadImportFinanceProductPackage = (data) => {
  return axios.postForm('/finance/products/bundlePackages/uploadImport', data)
}

export const executeImportFinanceProductPackage = (data) => {
  return axios.post('/finance/products/bundlePackages/executeImport', data)
}

export const uploadImportFinanceProductConversion = (data) => {
  return axios.postForm('/finance/products/conversions/uploadImport', data)
}

export const executeImportFinanceProductConversion = (data) => {
  return axios.post('/finance/products/conversions/executeImport', data)
}

export const getStockProduct = (id, warehouseIds, transDate) => {
  const params = cleanBlankValue({
    warehouse_ids: warehouseIds,
    trans_date: transDate,
  })

  return axios.get(`/finance/products/${id}/stocks`, { params })
}

export const getStockProducts = (params) => {
  return axios.get('/finance/products/stocks', { params })
}

export const getFinanceProducts = (params) => {
  params = cleanBlankValue(params)
  return axios.get('/finance/products', { params })
}

export const getFinanceProductsOverview = (params) => {
  params = cleanBlankValue(params)
  return axios.get('/finance/products/overview', { params })
}

export const getFinanceProduct = (id, params) => {
  return axios.get(`/finance/products/${id}`, { params })
}

export const getFinanceProductLog = (id, params) => {
  return axios.get(`/finance/products/${id}/logs`, { params })
}

export const getFinanceProductStockMovementChart = (params) => {
  return axios.get('/finance/products/stockMovementStats', { params })
}

export const getFinanceProductMovementByCategoryChart = (params) => {
  return axios.get('/finance/products/movementProductByCategoryStats', { params })
}

export const getFinanceProductValueMovementChart = (params) => {
  return axios.get('/finance/products/movementProductValueStats', { params })
}

export const getFinanceProductSalesPurchaseChartDetail = (id, params) => {
  return axios.get(`/finance/products/${id}/salePurchaseStats`, { params })
}

export const getFinanceProductStockMovementChartDetail = (id, params) => {
  return axios.get(`/finance/products/${id}/stockMovementStats`, { params })
}

export const getConversionProduct = (id) => {
  return axios.get(`/finance/products/conversions/${id}`)
}

export const getFinanceProductStockMovements = (id, params) => {
  return axios.get(`/finance/products/${id}/stockMovements`, { params })
}

export const exportFinanceProductStockMovements = (params) => {
  const { id, ...restParams } = params

  return axios.get(`/finance/products/${id}/stockMovements`, {
    params: restParams,
    responseType: 'arraybuffer',
  })
}

export const getFinanceProductTransactions = (id, params) => {
  return axios.get(`/finance/products/${id}/transactions`, { params })
}

export const archiveFinanceProduct = (id) => {
  return axios.patch(`/finance/products/${id}/archive`)
}

export const unarchiveFinanceProduct = (id) => {
  return axios.patch(`/finance/products/${id}/unarchive`)
}

export const deleteFinanceProduct = (id) => {
  return axios.delete(`/finance/products/${id}`)
}

export const inputMassDeleteProduct = (data) => {
  return axios.post('/finance/products/inputMassDelete', data)
}

export const executeMassDeleteProduct = (data) => {
  return axios.delete('/finance/products/executeMassDelete', { data })
}

export const getAllStockProducts = (requests) => {
  return axios.all(requests).then(axios.spread((...responses) => responses))
}

export const exportProducts = (params) => {
  params = cleanBlankValue(params)
  return axios.get('/finance/products/export', { responseType: 'arraybuffer', params })
}

export const exportProductsPackage = (params) => {
  params = cleanBlankValue(params)

  return axios.get('/finance/products/bundlePackage/export', {
    responseType: 'arraybuffer',
    params,
  })
}

export const exportProductsManufacture = (params) => {
  params = cleanBlankValue(params)

  return axios.get('/finance/products/bundleManufactures/export', {
    responseType: 'arraybuffer',
    params,
  })
}

export const editUnitConversion = (id, data) => {
  return axios.put(`/finance/products/${id}/units`, data)
}

export const getProductsPriceRule = (params) => {
  return axios.get(`/finance/products/priceRules`, { params })
}

export const getFinanceProductRoles = (id) => {
  return axios.get(`/finance/products/${id}/roles`)
}

export const addFinanceProductRoles = (id, data) => {
  return axios.post(`/finance/products/${id}/roles`, data)
}

export const getSuggestionBarcodeFinanceProduct = (params) => {
  return axios.get('/finance/products/suggestionBarcode', { params })
}

export const inputMassUnarchiveProduct = (data) => {
  return axios.post('/finance/products/inputMassUnarchive', data)
}

export const executeMassUnarchiveProduct = (data) => {
  return axios.post('/finance/products/executeMassUnarchive', data)
}

export const getProductSummary = (id) => {
  return axios.get(`/finance/products/${id}/summary`)
}

export const getProductWarehouseQtyStats = (id) => {
  return axios.get(`/finance/products/${id}/warehouseQtyStats`)
}

export const getStockStatusStats = (params) => {
  return axios.get(`/finance/products/stockStatusStats`, { params })
}

export const getStockPerCategoryStats = (params) => {
  return axios.get(`/finance/products/stockPerCategoryStats`, { params })
}

export const getProductPerCategoryStats = (params) => {
  return axios.get(`/finance/products/productPerCategoryStats`, { params })
}

export const getProductsSummary = (params) => {
  return axios.get(`/finance/products/summary`, { params })
}

export const getSuggestionFinanceProduct = (params, options) => {
  return axios.get('/finance/products/suggestionPerPage', { params, ...options })
}

export const getFinanceProductSerialNumbers = ({ id, ...params }, options) => {
  return axios.get(`/finance/products/${id}/serial-numbers`, { params, ...options })
}

export const getProductLastPrices = (params) => {
  return axios.get('/finance/products/last_prices', { params })
}

export const getProductLastBuyPrices = (params) => {
  return axios.get('/finance/products/last_buy_prices', { params })
}

export const getProductDetailSuggestion = (id, params) => {
  return axios.get(`/finance/products/${id}/suggestion`, { params })
}

export const getProductValuesDetails = () => {
  return axios.get('finance/products/productValuesDetails')
}
