import React from 'react'
import { useIntl } from 'react-intl'
import { Popover, Skeleton } from 'antd'
import styled from 'styled-components'


const HPPSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }

  .ant-skeleton-title {
    margin: 0;
  }
`
const LoadingInvalidData = () => {
  const { formatMessage } = useIntl()
  return (
    <Popover
      content={formatMessage({ id: 'financeProduct.data_is_being_processed' })}
      className="d-flex"
    >
      {Array(3)
        .fill(undefined)
        .map((__, index) => (
          <HPPSkeleton
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            active
            title={{
              style: { borderRadius: 999999, width: 12, height: 12 },
            }}
            paragraph={{ rows: 0 }}
            className="mx-1 d-flex align-items-center"
          />
        ))}
    </Popover>
  )
}

export default LoadingInvalidData
